import React from 'react'
import './HomepagePromo.module.scss'
import { HOMEPAGE_PROMO } from '../../../shared/constants';
import HomepageSlider from '../../../components/Slider/HomepageSlider';


const HomepagePromo = () => (
  <div className='homepage-promo-wrapper'>
    <h2 className='homepage-promo-title'>Ada Promo Spesial dan Rewards Menarik Menantimu!</h2>
    <div className='homepage-promo-carousel-wrapper'>
      <HomepageSlider
        sliderData={{
          desktop: {
            height: 220,
            width: 357,
            visibleItems: 4,
          },
          mobile: {
            height: 172,
            width: 215,
          },
        }}
        items={
          HOMEPAGE_PROMO.map((i, idx) => (
            <div >
              <a href={i.redirectURL} target='blank'>
                <img
                  alt={i.alt}
                  loading='lazy'
                  src={i.imgURL}
                  className='homepage-promo-image'
                  key={`homepage-promo-${idx}`}
                />
              </a>
            </div>
          ))
        }
      />
    </div>
  </div>
);

export default HomepagePromo;